import { Link } from 'gatsby'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon } from '@heroicons/react/solid'
import Checklist from 'icons/checklist.svg'
import CallIcon from 'icons/call.svg'
import Molecule from 'icons/molecule.svg'

const solutions = [
  {
    name: 'Choroby',
    description: 'O najczęstszych przypadłościach laryngologicznych',
    href: '/choroby-laryngologiczne',
    icon: Molecule,
  },
  {
    name: 'Badania i zabiegi',
    description: 'Informacje o badaniach i zabiegach wykonywanych w An-med ',
    href: '/badania-i-zabiegi',
    icon: Checklist,
  },
  {
    name: 'Kontakt',
    description: 'Skontaktuj się z nami',
    href: '/kontakt',
    icon: CallIcon,
  },
]

export const Menu = () => {
  return (
    <>
      <Popover className="relative block md:hidden" as="nav">
        <Popover.Button>
          <MenuIcon className="w-8 h-8" />
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute z-10 -right-8 sm:-right-12 top-8 overflow-hidden rounded-b-lg shadow-lg">
            <ul
              className="relative grid gap-8 bg-white p-7 lg:grid-cols-2 w-[calc(100vw)] lg:w-[800px]"
              id="breadcrumblist"
              itemScope
              itemType="http://schema.org/BreadcrumbList"
            >
              {solutions.map(item => (
                <li
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                  key={item.name}
                >
                  <Link
                    to={item.href}
                    className="-m-3 flex items-center rounded-lg p-4 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                    itemProp="item"
                  >
                    <div className="flex h-10 w-10 shrink-0 items-center justify-center text-black sm:h-12 sm:w-12">
                      <item.icon aria-hidden="true" />
                    </div>
                    <div className="ml-4">
                      <p
                        className="text-sm font-medium text-gray-900"
                        itemProp="name"
                      >
                        {item.name}
                      </p>
                      <p className="text-sm text-gray-500">
                        {item.description}
                      </p>
                      <meta itemProp="position" content="1" />
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </Popover.Panel>
        </Transition>
      </Popover>
      <nav className="hidden md:block">
        <ul
          id="breadcrumblist"
          itemScope
          itemType="http://schema.org/BreadcrumbList"
          className="flex items-center relative hidden md:flex -mr-4 space-x-4"
        >
          {solutions.map(item => (
            <li
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
              key={item.name}
            >
              <Link
                key={item.name}
                to={item.href}
                className="rounded-lg text-neutral-800 py-2 px-4 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 text-lg"
                activeClassName="!text-orange-500"
                itemProp="item"
              >
                <span itemProp="name">{item.name}</span>
                <meta itemProp="position" content="1" />
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}
