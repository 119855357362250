import 'styles/global.css'

import { AnimatePresence } from 'framer-motion'
import { ShouldUpdateScrollArgs, WrapPageElementBrowserArgs } from 'gatsby'
import Header from 'components/Header'

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => (
  <>
    <Header />
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}: ShouldUpdateScrollArgs) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation

  const TRANSITION_DELAY = 0.4 * 1000 * 2
  const savedPosition = getSavedScrollPosition(location) || [0, 0]

  window.history.scrollRestoration = 'manual'

  const isArticlePage =
    location.pathname.split('/').filter(phrase => phrase).length === 2

  if (isArticlePage) {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  }
  // if we used the browser's forwards or back button
  else {
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}
