import { Link } from 'gatsby'
import { Menu } from './Menu'
import Logo from 'icons/logo-color-full.svg'
import { useAnimateNavbarEffect } from 'hooks'

const Header = () => {
  const scrolled = useAnimateNavbarEffect()

  const headerClassNames = scrolled ? `shadow-lg bg-white/60` : 'bg-white'
  return (
    <header
      className={`w-full sticky top-0 z-20 py-8 px-4 md:px-4 lg:px-4 backdrop-filter backdrop-blur-lg duration-500 ${headerClassNames}`}
    >
      <div className="max-w-screen-xl px-4 sm:px-8 md:px-12 lg:px-12 flex justify-between items-center mx-auto">
        <Link to="/" title="Strona główna an-med" className="w-32 h-8">
          <Logo />
        </Link>
        <Menu />
      </div>
    </header>
  )
}

export default Header
