import { useState, useEffect } from 'react'

export const useAnimateNavbarEffect = () => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 104) {
        setScrolled(true)
      } else setScrolled(false)
    }
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  return scrolled
}
